import { useEffect, useState } from "react";
import { getCollectionsClient } from "../services/clientData";

function useGetCollection() {
    const [colecciones, setColecciones] = useState(null);
    const [loading, setLoading] = useState(true);

    const getCollections = async () => {
        setLoading(true);
        const res = await getCollectionsClient();
        setColecciones(res);
        setLoading(false)
    }
    useEffect(() => {
        getCollections();
      },[]);
    
      return {
        colecciones,
        loading,
      };
}

export {useGetCollection}