import React from "react";
import { useContractMetadata, useNFTsByOwner } from "../../hooks/useContractData";

function CardsNfts({ nfts }) {
  const tokenId = (tokenId) => {
    const id = tokenId.split('')
    id.reverse()

    let letMap = 0
    id.map((item, index) => item !== '0' && item !== 'x' ? letMap = index : '')
    //console.log(letMap)
    const arrId = id.slice(0, letMap + 1)

    const temp = arrId.reverse().toString().replace(/,/g, '')

    return parseInt(temp,16)
  }

  return (
    <>
      <div className="delegados_images">
        <ul>
          {nfts && nfts.map((nft) =>(
            <li key={nft.id.tokenId}>
              <img className="img-fluid" src={nft.media[0].gateway} alt={nft.id.tokenId}/>
              <span>
              <p>tokenId: {tokenId(nft.id.tokenId)}</p>
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export function Collection({ collection}) {

  const contractId = collection.contract;
  const { metadata } = useContractMetadata({ contractId });
  const { nftsAccount } = useNFTsByOwner({contractId})

  if (!metadata) {
    return <p>Loading ...</p>;
  }


  return (
    <>
    {/* {console.log(collection.contract)} */}
      {metadata && (
        <>
          {nftsAccount.length > 0 && <p>------{metadata.contractMetadata.name}-----</p>}
          {/* <CardsNfts nfts={NFTS} /> */}
          {nftsAccount && <CardsNfts nfts={nftsAccount}/>}
        </>
      )}
    </>
  );
}
