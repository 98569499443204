import Particles from 'react-tsparticles';
import React, { useCallback } from "react";
import { loadFull } from "tsparticles";

const ParcticleBackground = () => {

    const particlesInit = useCallback(async engine => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log("");
    }, []);
          

  return (
      <div id="particles_area">      
          <Particles
          id="tsparticles" 
          init={particlesInit} 
          loaded={particlesLoaded} 
          options={{
              
              "particles": {
                  "number": {
                    "value": 655,
                    "density": {
                      "enable": true,
                      "value_area": 1789.1476416322727
                    }
                  },
                  "color": {
                    "value": "#ffffff"
                  },
                  "shape": {
                    "type": "circle",
                    "stroke": {
                      "width": 0,
                      "color": "#000000",
                    },
                    "polygon": {
                      "nb_sides": 5
                    },
                    "image": {
                      "src": "img/github.svg",
                      "width": 100,
                      "height": 100
                    }
                  },
                  "opacity": {
                    "value": 0.48927153781200905,
                    "random": false,
                    "anim": {
                      "enable": true,
                      "speed": 0.5,
                      "opacity_min": 0,
                      "sync": false
                    }
                  },
                  "size": {
                    "value": 2,
                    "random": true,
                    "anim": {
                      "enable": true,
                      "speed": 2,
                      "size_min": 0,
                      "sync": false
                    }
                  },
                  "line_linked": {
                    "enable": false,
                    "distance": 150,
                    "color": "#ffffff",
                    "opacity": 0.4,
                    "width": 1
                  },
                  "move": {
                    "enable": true,
                    "speed": 0.2,
                    "direction": "none",
                    "random": true,
                    "straight": false,
                    "out_mode": "out",
                    "bounce": false,
                    "attract": {
                      "enable": false,
                      "rotateX": 600,
                      "rotateY": 1200
                    }
                  }
                },   
                "style":{
                  "position" : "absolute",
                   "width":"100%",
                  "height":"100%",
                   "z-index":0,
                  // "width": '100%',
                  // "height": '100%',
                  // "z-index": 0
                  // "height": "50%",
                //  "position": "fixed",
                  // "height": "50%",
                  // "margin-top": "50vh"
                },              
                "interactivity": {
                  // "detect_on": "canvas",
                  "events": {
                    "onhover": {
                      "enable": true,
                      "mode": "bubble"
                    },
                    "onclick": {
                      "enable": true,
                      "mode": "push"
                    },
                    "resize": true
                  },
                  "modes": {
                    "grab": {
                      "distance": 400,
                      "line_linked": {
                        "opacity": 1
                      }
                    },
                    "bubble": {
                      "distance": 83.91608391608392,
                      "size": 1,
                      "duration": 3,
                      "opacity": 1,
                      "speed": 3
                    },
                    "repulse": {
                      "distance": 200,
                      "duration": 0.4
                    },
                    "push": {
                      "particles_nb": 4
                    },
                    "remove": {
                      "particles_nb": 2
                    }
                  }
                },
                "retina_detect": true                
              }}
              />
              
              </div>
    
  )
}

export default ParcticleBackground